.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.dashboard {
  min-height: 100vh;
  direction: ltr;
}
.dashboard .header {
  /* padding-left: 5rem; */
}
.dashboard .content {
  min-height: fit-content;
  height: 100%;
  direction: ltr;
}

.dashboard ul {
  display: block;
}

.main_heading {
  text-align: center;
  margin: 2rem 0;
  padding: 2rem 0 0;
  color: var(--new-bg-color);
}
