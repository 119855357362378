.two-thirds-image-left-one-third-text-right {
    position: relative;
}

.two-thirds-image-left-one-third-text-right .image img {
    width: 100%;
}

@media (min-width: 700px) {
    .two-thirds-image-left-one-third-text-right {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 1rem;
    }
}

@media (min-width: 1170px) {
    .two-thirds-image-left-one-third-text-right {
        grid-column-gap: 2rem;
    }
}

@media (min-width: 700px) {
    .two-thirds-image-left-one-third-text-right .image {
        grid-column: 1/8;
        height: 100%;
    }

    .two-thirds-image-left-one-third-text-right .image img {
        /* height: 70vh; */
        -o-object-fit: cover;
        object-fit: cover;
        position: sticky;
        top: 0;
    }
}

@media (min-width: 1170px) {
    .two-thirds-image-left-one-third-text-right .image {
        grid-column: 1/7;
    }
}

.two-thirds-image-left-one-third-text-right .text {
    padding: 1rem;
}

@media (min-width: 700px) {
    .two-thirds-image-left-one-third-text-right .text {
        grid-column: 8/span all;
    }
}

@media (min-width: 1170px) {
    .two-thirds-image-left-one-third-text-right .text {
        grid-column: 7/span all;
    }
}

.two-thirds-image-left-one-third-text-right .text .title {
    /* font-size: 3rem; */
    margin-top: 2rem;
}

@media (min-width: 700px) {
    .two-thirds-image-left-one-third-text-right .text .title {
        /* font-size: 4rem; */
    }
}

.two-thirds-image-left-one-third-text-right .text .lead {
    /*font-style: italic;
    */
    font-weight: bold;
    font-size: 14px;
    margin: 0 0 1rem;
    padding: 5px 10px;
    line-height: 1.5;
    display: inline-block;
    border: 1px solid #eee;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

@media (min-width: 700px) {
    .two-thirds-image-left-one-third-text-right .text .lead {
        font-size: 16px;
    }
}

.one-by-one {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    margin: 0.5rem;
}

.wide-tall-cap-left {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;
    margin: 0.5rem;
}

@media (min-width: 600px) {
    .wide-tall-cap-left {
        grid-template-columns: repeat(12, 1fr);
    }
}

@media (min-width: 600px) {
    .wide-tall-cap-left .image-1 {
        grid-row: 1;
        grid-column: 1/-1;
    }
}

@media (min-width: 600px) {
    .wide-tall-cap-left .image-2 {
        grid-row: 2;
        grid-column: 6/span 7;
    }
}

.wide-tall-cap-left .caption {
    max-width: 70vw;
}

@media (min-width: 600px) {
    .wide-tall-cap-left .caption {
        font-size: 0.8rem;
        margin: 2rem 0.5rem;
    }
}

@media (min-width: 600px) {
    .wide-tall-cap-left .caption-1 {
        grid-row: 2;
        grid-column: 1/span 2;
    }
}

@media (min-width: 600px) {
    .wide-tall-cap-left .caption-2 {
        grid-row: 2;
        grid-column: 3/span 2;
    }
}

.three-col-text {
    -moz-columns: 3;
    columns: 3;
    -moz-column-width: 300px;
    column-width: 300px;
    padding: 1rem;
}

.three-col-text p:first-child {
    margin-top: 0;
}