.signup {
  padding-top: 80px !important;
}

.signup .signup_cont {
  padding: 50px 40px 20px;
  width: 40%;
  box-shadow: 0px 0px 30px rgba(13, 12, 13, 0.2);
}

@media (max-width: 1199px) {
  .signup .signup_cont {
    width: 50%;
  }
}

@media (max-width: 991px) {
  .signup .signup_cont {
    width: 70%;
  }
}

@media (max-width: 767px) {
  .signup .signup_cont {
    width: 80%;
  }
}

@media (max-width: 576px) {
  .signup .signup_cont {
    width: 100%;
  }
}

.signup .signup_cont h2 {
  color: #333;
  text-align: center;
  font-size: 26px;
  font-weight: 600;
}

.signup .signup_cont h2+p {
  text-align: center;
  font-size: 17px;
  color: #666;
  margin: 20px 0 10px;
}

.signup .signup_cont form {
  margin-top: 10px;
}

.signup .signup_cont form label {
  font-size: 17px;
  font-weight: 500;
  color: #333;
  margin: 20px 0 10px;
  cursor: pointer;
}

.signup .signup_cont form input {
  width: 100%;
  padding: 15px 20px;
  border: none;
  border: 1px solid #ccc;
  box-shadow: 0 0 3px #fff;
  border-radius: 10px;
  caret-color: var(--main-color);
  color: var(--main-color);
  transition: var(--main-transition);
  font-size: 14px;
}

.signup .signup_cont form input:focus {
  outline: none;
  border-color: var(--main-color);
}

@media (max-width: 767px) {
  .signup .signup_cont form .signEnd {
    flex-direction: column;
  }
}

.signup .signup_cont form .signEnd .tologin {
  color: #666;
  margin: 0;
}

@media (max-width: 767px) {
  .signup .signup_cont form .signEnd .tologin {
    margin-bottom: 15px;
  }
}

.signup .signup_cont form .signEnd .tologin .loginLink {
  color: var(--main-color);
  font-weight: 500;
  font-size: 15px;
}