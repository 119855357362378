header {
  background-color: #fff;
  position: sticky;
  top: 0;
  transition: var(--main-transition);
  z-index: 100;
  /* box-shadow: #64646f33 0 25px 20px -20px; */
  /* box-shadow: 0px -15px 10px -15px #111; */
  box-shadow: 0px -25px 20px -20px #64646f17,
    0px 25px 20px -20px #64646f33;
}

header.scroll {
  background-color: var(--bg-dark);
  box-shadow: 0 1px 2px #fff;
  margin-top: -1px;
}

@media (max-width: 480px) {
  header .container {
    /* flex-direction: column; */
  }
}

header .container .logo {
  width: fit-content;
  color: var(--main-color);
  font-family: var(--font-alt);
  font-weight: 700;
  font-size: 30px;
}

@media (max-width: 991px) {
  header .container nav {
    justify-content: flex-end !important;
  }
}

header .container nav .pages {
  padding-left: 60px;
}

@media (max-width: 991px) {
  header .container nav .pages {
    position: absolute;
    left: 0;
    top: -600%;
    opacity: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    padding-left: 0;
    transition: 0.5s;
    background-color: var(--alt-color);
  }

  header .container nav .pages.open {
    opacity: 1;
    top: 100%;
  }

  header .container nav .pages.open a {
    width: 100%;
    padding-left: 20px;
    color: #000;
  }

  header .container nav .pages.open a:hover {
    padding-left: 25px;
  }
}

header .container nav .pages a {
  transition: var(--main-transition);
  color: #000;
  font-size: 15px;
  font-weight: 600;
  padding: 15px;
}

header .container nav .pages a:hover,
header .container nav .pages a.active {
  color: var(--main-color);
}

header .container nav .pages a.sale {
  position: relative;
}

@media (max-width: 991px) {
  header .container nav .pages a.sale:hover::before {
    left: 90px;
  }

  header .container nav .pages a.sale:hover::after {
    left: 75px;
  }
}

header .container nav .pages a.sale::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  right: 27px;
  top: 14px;
  border: 5px solid;
  border-color: var(--main-color) transparent transparent transparent;
  transition: var(--main-transition);
}

@media (max-width: 991px) {
  header .container nav .pages a.sale::before {
    top: 15px;
    left: 80px;
  }
}

header .container nav .pages a.sale::after {
  content: "Sale";
  position: absolute;
  top: -8px;
  right: 10px;
  border-radius: 30px;
  width: fit-content;
  padding: 2px 10px;
  background-color: var(--main-color);
  color: #fff;
  font-size: 13px;
  transition: var(--main-transition);
}

@media (max-width: 991px) {
  header .container nav .pages a.sale::after {
    top: -8px;
    left: 65px;
  }
}

header .container nav .info {
  align-items: center;
}

header .container nav .info .search {
  transition: var(--main-transition);
}

header .container nav .info .search input {
  width: 100%;
  padding: 15px 20px;
  border: none;
  border: 1px solid #ccc;
  box-shadow: 0 0 3px #fff;
  border-radius: 10px;
  caret-color: var(--main-color);
  color: var(--main-color);
  transition: var(--main-transition);
  position: absolute;
  opacity: 0;
  left: -600%;
  top: 100%;
}

header .container nav .info .search input:focus {
  outline: none;
  border-color: var(--main-color);
}

header .container nav .info .search.open input {
  left: 0;
  opacity: 1;
}

header .container nav .info .hover_btn:hover {
  border: 1px solid transparent !important;
}

header .container nav .info>a {
  position: relative;
}

header .container nav .info>a .number {
  position: absolute;
  top: 6px;
  right: 9px;
  color: #fff;
  background-color: var(--main-color);
  font-size: 12px;
  font-weight: 600;
  padding: 0 4px;
  border-radius: 25%;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  text-align: center;
}

header .container nav .info>a:hover svg {
  color: var(--main-color);
}

header .container nav .info svg {
  color: #000;
  padding: 15px;
  font-size: 21px;
  transition: var(--main-transition);
  cursor: pointer;
}

header .container nav .info svg:hover {
  color: var(--main-color);
}

header .container nav .info .toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  padding: 15px;
  cursor: pointer;
  transition: var(--main-transition);
}

header .container nav .info .toggle span {
  display: block;
  width: 30px;
  height: 3px;
  border-radius: 10px;
  background-color: #000;
  transition: var(--main-transition);
}

header .container nav .info .toggle span:nth-child(2) {
  transition-delay: 0.1s;
}

header .container nav .info .toggle.open {
  gap: 0;
}

header .container nav .info .toggle.open span:first-child {
  transition-delay: 0.2s;
  transform: translateY(2.5px) rotate(-45deg);
}

header .container nav .info .toggle.open span:nth-child(2) {
  opacity: 0;
}

header .container nav .info .toggle.open span:last-child {
  transition-delay: 0.2s;
  transform: translateY(-2.5px) rotate(45deg);
}