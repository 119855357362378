.Category .image_holder {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.Category .image_holder:hover img {
  transition: var(--main-transition);
  animation: imganime 3.5s linear;
}

.Category .image_holder img {
  max-width: 100%;
}

@media (max-width: 576px) {
  .Category .image_holder img {
    width: 100%;
  }
}

.Category .image_holder .cat {
  background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(51, 51, 51, 0.098), #333);
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 2;
  text-align: center;
}

@media (min-width: 577px) and (max-width: 767px) {
  .Category .image_holder .cat {
    width: 82%;
  }
}

@media (max-width: 767px) {
  .Category .image_holder .cat p {
    margin-top: 50% !important;
  }

  .Category .image_holder .cat a {
    margin-top: 20px !important;
  }
}

.Category .image_holder .cat:hover p {
  margin-top: 50%;
}

.Category .image_holder .cat:hover a {
  opacity: 1;
}

.Category .image_holder .cat p {
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  margin-top: 70%;
  transition: 0.7s;
}

.Category .image_holder .cat a {
  display: block;
  transition: 0.7s;
  margin-top: 30px;
  font-weight: 600;
  opacity: 0.8;
  color: var(--main-color);
}

@media (max-width: 767px) {
  .Category .image_holder .cat a {
    margin-top: 50px;
  }
}

.Category .image_holder .cat a:hover {
  opacity: 1;
  transform: scale(1.1);
}