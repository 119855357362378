.Trending {
  background-color: var(--alt-color);
}

.Trending .mySwiper figure {
  cursor: pointer;
  transition: var(--main-transition);
  position: relative;
  padding-bottom: 15px;
}

.Trending .mySwiper figure:hover {
  box-shadow: 1px 1px 20px var(--main-color);
}

.Trending .mySwiper figure:hover img {
  animation: imganime 3.5s linear;
}

.Trending .mySwiper figure:hover .trend_image_hold .trend_image_option {
  opacity: 1;
}

.Trending .mySwiper figure .trend_image_hold {
  overflow: hidden;
  position: relative;
}

.Trending .mySwiper figure .trend_image_hold img {
  width: 100%;
  height: 20rem;
}

.Trending .mySwiper figure .trend_image_hold .trend_image_option {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: var(--main-transition);
}

@media (max-width: 470px) {
  .Trending .mySwiper figure .trend_image_hold .trend_image_option {
    top: 60%;
  }
}

@media (max-width: 767px) {
  .Trending .mySwiper figure .trend_image_hold .trend_image_option {
    opacity: 1;
  }
}

.Trending .mySwiper figure .trend_image_hold .trend_image_option li {
  background-color: #fff;
  padding: 10px 20px;
  transition: var(--main-transition);
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .Trending .mySwiper figure .trend_image_hold .trend_image_option li {
    font-size: 23px;
  }
}

@media (max-width: 767px) {
  .Trending .mySwiper figure .trend_image_hold .trend_image_option li {
    font-size: 21px;
  }
}

.Trending .mySwiper figure .trend_image_hold .trend_image_option li:hover {
  background-color: var(--main-color);
  box-shadow: 0 0 7px #fff;
}

.Trending .mySwiper figure .trend_image_hold .trend_image_option li:hover svg {
  color: #fff;
}

.Trending .mySwiper figure .trend_image_hold .trend_image_option li svg {
  color: var(--main-color);
  transition: var(--main-transition);
}

.Trending .mySwiper figure .trend_image_hold .trend_image_option li:first-child {
  position: relative;
  padding: 30px;
}

.Trending .mySwiper figure .trend_image_hold .trend_image_option li:first-child:hover::before,
.Trending .mySwiper figure .trend_image_hold .trend_image_option li:first-child:hover.favicon::before {
  color: #fff;
}

.Trending .mySwiper figure .trend_image_hold .trend_image_option li:first-child::before {
  font-family: "Font Awesome 5 Free";
  content: "\f004";
  font-weight: 200;
  position: absolute;
  color: var(--main-color);
  transition: var(--main-transition);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Trending .mySwiper figure .trend_image_hold .trend_image_option li:first-child.favicon::before {
  font-family: "Font Awesome 5 Free";
  content: "\f004";
  font-weight: 200;
  position: absolute;
  color: var(--main-color);
  transition: var(--main-transition);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 900;
}

.Trending .mySwiper figure figcaption {
  margin-top: 20px;
  text-align: center;
}

.Trending .mySwiper figure figcaption .image_desc {
  font-weight: 500;
  font-size: 15px;
  color: #333;
  transition: var(--main-transition);
}

.Trending .mySwiper figure figcaption .image_desc:hover {
  color: var(--main-color);
}

.Trending .mySwiper figure figcaption .price {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-weight: 600;
  font-size: 16px;
}

@media (max-width: 767px) {
  .Trending .mySwiper figure figcaption .price {
    flex-direction: column;
    gap: 10px;
  }
}

.Trending .mySwiper figure figcaption .price .currentPrice {
  color: #666;
  transition: var(--main-transition);
}

.Trending .mySwiper figure figcaption .price .currentPrice:hover {
  color: #222;
}

.Trending .mySwiper figure figcaption .price .oldprice {
  text-decoration: line-through;
  color: var(--main-color);
}