.cat {
    overflow: hidden;
}

.cat .img_cat {
    transition: .3s;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
}

.cat:hover .img_cat {
    transform: scale(1.1) rotate(-3deg);
    -webkit-transform: scale(1.1) rotate(-3deg);
    -moz-transform: scale(1.1) rotate(-3deg);
    -ms-transform: scale(1.1) rotate(-3deg);
    -o-transform: scale(1.1) rotate(-3deg);
}

.ant-row {
    gap: 10px;
}

:where(.css-dev-only-do-not-override-dkbvqv).ant-card .ant-card-body {
    padding: 18px 10px;
    overflow: hidden;
    position: relative;
    background: white;
}