.store {
  width: 100%;
  height: 80px;
  /* background-image: url(../../assets/store3.jpg);
  background-image: url(../../assets/Logo-Organica-Group.png); */
  background-size: cover;
  margin-bottom: 7rem;
}

.store .store_info {
  position: relative;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px 25px;
  text-align: center;
  color: #555;
  flex-direction: column;
  box-shadow: 0 0 5px #fff;
}

.store .store_info .store_name {
  font-size: 30px;
  font-weight: 700;
  color: #333;
  font-family: var(--font-alt);
  /* text-transform: uppercase; */
}

.store .store_info address {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0 45px;
}

.store .store_info address::after {
  content: "";
  position: absolute;
  width: 70px;
  height: 3px;
  background-color: #888;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
}

.store .store_info .store_open {
  font-size: 17px;
  font-weight: 500;
}
