:root {
  --font-base: "Montserrat", sans-serif;
  --font-alt: "Playfair Display", serif;
  --main-color: #3c87c5;
  --alt-color: rgb(255 255 255 / 95%);
  --bg-dark: #191919;
  --bg-dark2: #142a2e;
  --main-transition: 0.3s;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-base) !important;
  background-color: var(--alt-color);
}

a {
  text-decoration: none !important;
}

ul {
  display: flex;
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}

::selection {
  color: var(--main-color);
  background-color: #333;
}

::placeholder {
  color: #666;
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom_button {
  margin: 20px 0;
  padding: 15px 20px;
  width: fit-content;
  border-radius: 30px;
  background-color: var(--main-color);
  color: #fff;
  transition: var(--main-transition);
  border: none;
  font-weight: 600;
}

.custom_button:hover {
  opacity: 0.8;
}

.main_heading {
  color: #222;
  font-family: var(--font-alt);
  font-size: 42px;
  font-weight: 800;
  transition: var(--main-transition);
  position: relative;
  width: fit-content;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 10px 20px;
  text-align: center;
}

.main_heading:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 4px 2px var(--main-color);
}

@keyframes imganime {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes flash {
  from {
    color: var(--main-color);
  }

  to {
    color: #fff;
  }
}

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-track {
  background-color: var(--alt-color);
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  border-radius: 15px;
}

::-webkit-scrollbar-corner {
  background-color: var(--alt-color);
}
