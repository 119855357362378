.swiper:hover .swiper-button-next,
.swiper:hover .swiper-button-prev {
  opacity: 0.4;
}

.swiper .swiper-slide {
  position: relative;
}

.swiper .swiper-slide.swiper-slide-active .text {
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
}

.swiper .swiper-slide .text {
  position: absolute;
  z-index: 5;
  top: 100%;
  left: 10%;
  color: rgb(51, 51, 51);
  transition: 2s;
  opacity: 0;
}

.swiper .swiper-slide .text .heading {
  font-size: 35px;
}

@media (max-width: 767px) {
  .swiper .swiper-slide .text .heading {
    font-size: 30px;
  }
}

@media (max-width: 576px) {
  .swiper .swiper-slide .text .heading {
    font-size: 20px;
  }
}

.swiper .swiper-slide .text .title {
  font-family: var(--font-alt);
  font-size: 60px;
  font-weight: 800;
}

@media (max-width: 767px) {
  .swiper .swiper-slide .text .title {
    font-size: 55px;
  }
}

@media (max-width: 576px) {
  .swiper .swiper-slide .text .title {
    font-size: 40px;
  }
}

.swiper .swiper-slide img {
  width: 100%;
  height: calc(100vh - 50px);
  /* object-fit: cover; */
  object-fit: fill;
}

@media (max-width:768px) {
  .swiper .swiper-slide img {
    height: calc(50vh - 50px);
  }
}

.swiper .swiper-button-next,
.swiper .swiper-button-prev {
  color: #666;
  transition: var(--main-transition);
  user-select: none;
  opacity: 0;
}

.swiper .swiper-button-next:hover,
.swiper .swiper-button-prev:hover {
  opacity: 1;
  color: var(--main-color);
}

.swiper .swiper-button-prev,
.swiper .swiper-rtl .swiper-button-next {
  left: 25px;
}

.swiper .swiper-button-next,
.swiper .swiper-rtl .swiper-button-prev {
  right: 25px;
}