/* start whatsapp_massenger   */
.whatsapp {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
}

@media (max-width: 768px) {
  body[dir="rtl"] .whatsapp {
    right: 20px;
  }
}

.whatsapp a img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 50%;
  /* background: #fff; */
  box-shadow: 0 4px 20px #23397d3a;
}

.whatsapp p {
  margin: 0;
  /* width: 250px; */
  background-color: #fff;
  text-align: start;
  padding: 5px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 20px #23397d3a;
}

.whatsapp a p strong {
}

/* end whatsapp_massenger   */

body[dir="ltr"] .whatsapp {
  left: 50px !important;
}
