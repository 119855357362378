body {
    /* background: rgb(99, 39, 120) */
}

.form-control:focus {
    box-shadow: none;
    border-color: var(--main-color);
}

.profile-button {
    background: var(--main-color);
    box-shadow: none;
    border: none;
}

.profile-button:hover {
    background: #0d6efd85;
}

.profile-button:focus {
    background: #096af9;
    box-shadow: none;
}

.profile-button:active {
    background: #096af9;
    box-shadow: none;
}

.back:hover {
    color: #096af9;
    cursor: pointer;
}

.labels {
    font-size: 11px;
}

.add-experience:hover {
    background: #096af9;
    color: #fff;
    cursor: pointer;
    border: solid 1px #096af9;
}