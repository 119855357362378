.contact .contact_img {
  width: 100%;
  height: 400px;
  background-image: url("../../assets/Logo-Organica-Group.png");
  background-size: cover;
  position: relative;
  background-position: 0px -45px;
  background-repeat: no-repeat;
}

@media (max-width: 991px) {
  .contact .contact_img {
    background-position: 0;
  }
}

.contact .contact_img::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

/* .contact .contact_img::after {
  content: "Contact";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 60px;
  font-weight: 800;
} */

.contact main {
  padding: 80px 0;
}

.contact main .col {
  border: 1px solid #ccc;
}

.contact main .col:last-child {
  border-left: 0;
}

@media (max-width: 767px) {
  .contact main .col:last-child {
    border-left: 1px solid #ccc;
    border-top: 0;
  }
}

.contact main .contact_message {
  padding: 50px;
}

.contact main .contact_message h4 {
  color: #333;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 400;
}

.contact main .contact_message form input {
  margin-bottom: 20px;
}

.contact main .contact_message form input,
.contact main .contact_message form textarea {
  width: 100%;
  padding: 15px 20px;
  border: none;
  border: 1px solid #ccc;
  box-shadow: 0 0 3px #fff;
  border-radius: 10px;
  caret-color: var(--main-color);
  color: var(--main-color);
  transition: var(--main-transition);
}

.contact main .contact_message form input:focus,
.contact main .contact_message form textarea:focus {
  outline: none;
  border-color: var(--main-color);
}

.contact main .contact_message form textarea {
  resize: none;
}

.contact main .contact_address {
  padding: 50px;
  padding-left: 80px;
}

.contact main .contact_address > div {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

.contact main .contact_address > div svg {
  color: var(--main-color);
  margin-top: 7px;
}

.contact main .contact_address > div > div p:first-child {
  font-size: 19px;
  font-weight: 400;
  color: #333;
}

.contact main .contact_address > div > div p:last-child {
  color: rgb(88, 99, 175);
}

.contact main .contact_address .address_text {
  color: #666 !important;
}
